import Papa from 'papaparse';

export const fetchData = async (holeDataUrl, assayDataUrl, holeColumns, assayColumns) => {
  const parseCSV = (url) => {
    return new Promise((resolve) => {
      Papa.parse(url, {
        download: true,
        header: true,
        complete: (result) => {
          resolve(result.data);
        },
      });
    });
  };

  const holeData = await parseCSV(holeDataUrl);
  const assayData = await parseCSV(assayDataUrl);

  const holes = {};
  const assays = {};
  holeData.forEach((hole) => {
    const holeId = hole[holeColumns['Hole No']];
    if(holeId.length<1){return}
    holes[holeId] = { ...hole };
  });

  assayData.forEach((assay) => {
    const holeId = assay[assayColumns['HoleID']];
    if (!assays[holeId]) {
      assays[holeId] = [];
    }
    assays[holeId].push({ ...assay });
  });

  return { holes, assays };
};
