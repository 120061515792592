import React, { useState, useEffect } from 'react';
import {
  BarChart,
  Bar,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

const HoistingStatistics = () => {
  // Data for the graphs
  const data = [
    { month: 'Jan', planTonnage: 50000, actualTonnage: 48000, planGrade: 2.0, actualGrade: 1.8 },
    { month: 'Feb', planTonnage: 55000, actualTonnage: 53000, planGrade: 1.9, actualGrade: 2.1 },
    { month: 'Mar', planTonnage: 60000, actualTonnage: 59000, planGrade: 2.2, actualGrade: 2.0 },
    { month: 'Apr', planTonnage: 65000, actualTonnage: 63000, planGrade: 2.5, actualGrade: 2.3 },
    { month: 'May', planTonnage: 70000, actualTonnage: 68000, planGrade: 2.7, actualGrade: 2.6 },
    { month: 'Jun', planTonnage: 75000, actualTonnage: 73000, planGrade: 2.8, actualGrade: 2.7 },
    { month: 'Jul', planTonnage: 80000, actualTonnage: 78000, planGrade: 2.5, actualGrade: 2.4 },
    { month: 'Aug', planTonnage: 78000, actualTonnage: 76000, planGrade: 2.3, actualGrade: 2.2 },
    { month: 'Sep', planTonnage: 76000, actualTonnage: 74000, planGrade: 2.1, actualGrade: 2.0 },
    { month: 'Oct', planTonnage: 74000, actualTonnage: 72000, planGrade: 1.8, actualGrade: 1.9 },
    { month: 'Nov', planTonnage: 72000, actualTonnage: 70000, planGrade: 1.5, actualGrade: 1.6 },
    { month: 'Dec', planTonnage: 70000, actualTonnage: null,    planGrade: 1.2, actualGrade: null },
  ];

  // Calculate content (grade * tonnage) and convert to kilograms
  const dataWithContent = data.map((item) => ({
    ...item,
    planContent: (item.planTonnage * item.planGrade) / 1000, // Convert to kilograms
    actualContent:
      item.actualTonnage && item.actualGrade
        ? (item.actualTonnage * item.actualGrade) / 1000
        : null,
  }));

  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const scrollContainer = document.getElementById('stats-scroll-container');

    const handleScroll = () => {
      if (scrollContainer.scrollTop > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    scrollContainer.addEventListener('scroll', handleScroll);

    return () => {
      scrollContainer.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="h-full w-full flex flex-col overflow-hidden">
      {/* Sticky Heading */}
      <div
        className={`sticky top-0 flex justify-center items-center transition-all duration-300 z-10 ${
          isScrolled
            ? 'bg-blue-500 text-white rounded-full mx-4 mt-2 py-1 px-4 shadow-md'
            : 'bg-white text-gray-800 py-2'
        }`}
      >
        <h2
          className={`font-bold transition-all duration-300 ${
            isScrolled ? 'text-lg' : 'text-xl md:text-2xl lg:text-3xl'
          }`}
        >
          Hoisting Trends
        </h2>
      </div>

      {/* Scrollable Content */}
      <div id="stats-scroll-container" className="flex-1 overflow-y-auto px-5 pt-4 pb-4">
        <div className="space-y-6">
          {/* Tonnage Trend */}
          <div className="bg-white p-4 rounded-lg shadow-lg">
            <h3 className="text-lg font-semibold mb-2 text-center text-gray-800">
              Tonnage Trend
            </h3>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={data}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="month" />
                <YAxis
                  domain={[45000, 80000]}
                  tickFormatter={(value) => `${value / 1000}k`}
                />
                <Tooltip />
                <Legend />
                <Bar dataKey="planTonnage" name="Plan" fill="#FF0000" />
                <Bar dataKey="actualTonnage" name="Actual" fill="#0000FF" />
              </BarChart>
            </ResponsiveContainer>
          </div>

          {/* Grade Trend */}
          <div className="bg-white p-4 rounded-lg shadow-lg">
            <h3 className="text-lg font-semibold mb-2 text-center text-gray-800">
              Grade Trend
            </h3>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart data={data}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="month" />
                <YAxis domain={[1.2, 2.8]} />
                <Tooltip />
                <Legend />
                <Line
                  type="monotone"
                  dataKey="planGrade"
                  name="Plan"
                  stroke="#FF0000"
                />
                <Line
                  type="monotone"
                  dataKey="actualGrade"
                  name="Actual"
                  stroke="#0000FF"
                />
              </LineChart>
            </ResponsiveContainer>
          </div>

          {/* Content Trend */}
          <div className="bg-white p-4 rounded-lg shadow-lg">
            <h3 className="text-lg font-semibold mb-2 text-center text-gray-800">
              Content Trend (in kgs)
            </h3>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={dataWithContent}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="month" />
                <YAxis
                  tickFormatter={(value) =>
                    value >= 1000
                      ? `${(value / 1000).toFixed(1)}k`
                      : value.toFixed(0)
                  }
                />
                <Tooltip formatter={(value) => value.toFixed(2)} />
                <Legend />
                <Bar dataKey="planContent" name="Plan" fill="#FF0000" />
                <Bar dataKey="actualContent" name="Actual" fill="#0000FF" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HoistingStatistics;
