import React, { useEffect } from 'react';
import HoistingStatistics from './HoistingStatistics'; // The previous Statistics component, renamed
import DevelopmentStatistics from './DevelopmentStatistics';

const Statistics = ({ statisticsType }) => {
  // Determine which component to render
  let StatisticsComponent;

  useEffect(() => {
    console.log('stats changed',statisticsType)
  }, [statisticsType]);

  switch (statisticsType) {
    case 'hoisting':
      StatisticsComponent = HoistingStatistics;
      break;
    case 'development':
      StatisticsComponent = DevelopmentStatistics;
      break;
    default:
      StatisticsComponent = () => (
        <div className="flex items-center justify-center h-full">
          <p>No statistics available.</p>
        </div>
      );
  }

  return <StatisticsComponent />;
};

export default Statistics;
