import { useFrame, useThree } from '@react-three/fiber'
import { useEffect} from 'react'
import { useClickStore } from '../store/clickStore'
import { useShallow } from 'zustand/react/shallow'
import TWEEN from 'tween'
import * as THREE from 'three'

export default function CameraControl({controls}) {
    const { camera } = useThree()

    const {zoomPosition,zoomToExtent,setZoomToExtent} = useClickStore(useShallow((state)=>({
        zoomPosition:state.zoomPosition,
        zoomToExtent:state.zoomToExtent,
        setZoomToExtent:state.setZoomToExtent
    })))

    useEffect(()=>{
        if(zoomToExtent){
            zoomToDefault(controls,camera)
            setZoomToExtent(false)
        }
    },[zoomToExtent])

    useEffect(()=>{
        if(zoomPosition){
            let campos = findPointC(camera.position, zoomPosition)
            console.log('zoom clicked',zoomPosition,camera.position,campos)
            new TWEEN.Tween(controls.current.target)
                .to(
                    {
                        x: zoomPosition.x,
                        y: zoomPosition.y,
                        z: zoomPosition.z,
                    },
                    1100
                )
                .easing(TWEEN.Easing.Cubic.Out)
                .start()

            new TWEEN.Tween(camera.position)
                .to(
                    {
                        x: campos.x,
                        y: campos.y,
                        z: campos.z,
                    },
                    1000
                )
                .easing(TWEEN.Easing.Cubic.Out)
                .start()
        }
    },[zoomPosition])

    // Add TWEEN.update() to the render loop
    useFrame(() => {
        TWEEN.update()
    })
    
  return (
    null
  )
}

function findPointC(pointA, pointB) {
    // Calculate the vector from A to B
  const vectorAB = new THREE.Vector3().subVectors(pointB, pointA);

  // Normalize the vector to get the direction
  const directionAB = vectorAB.clone().normalize();

  // Calculate the position of point C
  const pointC = new THREE.Vector3().addVectors(pointA, directionAB.multiplyScalar(vectorAB.length() - 10));

  return pointC;
  }

  function zoomToDefault(controls,camera){
    const default_ = {
        camPos: {
            x: 166,
            y: 1225,
            z: 2500,
        },
        lookAt: {
            x: 166,
            y: 0,
            z: 3,
        }
    }


    new TWEEN.Tween(controls.current.target)
        .to(
            {
                x: default_.lookAt.x,
                y: default_.lookAt.y,
                z: default_.lookAt.z,
            },
            2100
        )
        .easing(TWEEN.Easing.Cubic.Out)
        .start()

    // change camera position
    new TWEEN.Tween(camera.position)
        .to(
            {
                x: default_.camPos.x,
                y: default_.camPos.y,
                z: default_.camPos.z,
            },
            2000
        )
        .easing(TWEEN.Easing.Cubic.Out)
        .start()

}

