import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { signInWithEmailAndPassword, signOut, onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { auth , db } from './firebase/firebaseConfig';
import Presentation from './components/presentation/Presentation';

function Authentication({project}) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [deviceId, setDeviceId] = useState('');
  const [deviceName, setDeviceName] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showDeviceNameInput, setShowDeviceNameInput] = useState(false);
  const [isDeviceRegistered, setIsDeviceRegistered] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorStyle, setErrorStyle] = useState({});

  useEffect(() => {
    // Check if deviceId exists in local storage
    const storedDeviceId = localStorage.getItem('deviceId');
    if (storedDeviceId) {
      setDeviceId(storedDeviceId);
    }

    // Check authentication state
    onAuthStateChanged(auth, (user) => {
      if (user) {
        checkDeviceActivation(user.email);
      } else {
        setIsLoggedIn(false);
      }
    });
  }, []);

  const checkDeviceActivation = async (email) => {
    try {
      const userRef = doc(db, `projects/${project}/users`, email);
      const userDoc = await getDoc(userRef);

      if (userDoc.exists()) {
        const devices = userDoc.data().devices;
        if (devices && Object.values(devices).some(device => device.deviceId === deviceId)) {
          const deviceNameRegistered = Object.keys(devices).find(key => devices[key].deviceId === deviceId);
          setDeviceName(deviceNameRegistered);
          setIsDeviceRegistered(true);
          const deviceData = devices[deviceNameRegistered];
          if (deviceData.activated) {
            setIsLoggedIn(true);
          } else {
            setErrorMessage(`This device (${deviceNameRegistered}) has not been activated, please contact Admin.`);
            setErrorStyle({
              backgroundColor: 'rgba(255, 165, 0, 0.2)',
              color: 'darkorange',
              padding: '10px',
              borderRadius: '5px',
              textAlign: 'center',
              marginTop: '10px',
            });
            handleLogout();
          }
        } else {
          setShowDeviceNameInput(true);
        }
      }
    } catch (error) {
      console.error('Error checking device activation:', error);
    }
  };

  const handleLogin = async () => {
    try {
      // Clear device name input and hide register device button
      setDeviceName('');
      setShowDeviceNameInput(false);
      setIsDeviceRegistered(false);
      setErrorMessage('');
      setErrorStyle({});

      // Sign in the user
      await signInWithEmailAndPassword(auth, username, password);

      console.log("logged")

      // Generate a new device ID if one does not exist
      if (!deviceId) {
        const newDeviceId = uuidv4();
        setDeviceId(newDeviceId);
        localStorage.setItem('deviceId', newDeviceId);
      }

      // Reference to the user document under the project
      const userRef = doc(db, `projects/${project}/users`, username);
      const userDoc = await getDoc(userRef);

      const currentLocation = window.location.href;
      const currentDate = new Date().toISOString();

      if (!userDoc.exists()) {
        // User document doesn't exist, prompt for device name
        setShowDeviceNameInput(true);
      } else {
        // Check if the device is already registered
        const devices = userDoc.data().devices;
        if (!devices || !Object.values(devices).some(device => device.deviceId === deviceId)) {
          // Device is not registered, prompt for device name
          setShowDeviceNameInput(true);
        } else {
          // Device is already registered
          const deviceNameRegistered = Object.keys(devices).find(key => devices[key].deviceId === deviceId);
          setDeviceName(deviceNameRegistered);
          setIsDeviceRegistered(true);
          const deviceData = devices[deviceNameRegistered];
          if (deviceData.activated) {
            // Update the last login date
            await updateDoc(userRef, {
              [`devices.${deviceNameRegistered}.lastLogin`]: currentDate,
            });
            setIsLoggedIn(true);
          } else {
            setErrorMessage(`This device (${deviceNameRegistered}) has not been activated, please contact Admin.`);
            setErrorStyle({
              backgroundColor: 'rgba(255, 165, 0, 0.2)',
              color: 'darkorange',
              padding: '10px',
              borderRadius: '5px',
              textAlign: 'center',
              marginTop: '10px',
            });
            handleLogout();
          }
        }
      }
    } catch (error) {
      console.error('Login failed:', error);
      setErrorMessage('The login credentials are incorrect.');
      setErrorStyle({
        backgroundColor: 'rgba(255, 0, 0, 0.2)',
        color: 'red',
        padding: '10px',
        borderRadius: '5px',
        textAlign: 'center',
        marginTop: '10px',
      });
    }
  };

  const handleRegisterDevice = async () => {
    if (!deviceName.trim()) {
      alert('Device name is required.');
      return;
    }

    try {
      const userRef = doc(db, `projects/${project}/users`, username);
      const userDoc = await getDoc(userRef);

      const currentLocation = window.location.href;
      const currentDate = new Date().toISOString();

      if (!userDoc.exists()) {
        // Create user document with the current device name and device ID
        await setDoc(userRef, {
          devices: {
            [deviceName]: {
              deviceId,
              activated: false,
              dateAdded: currentDate,
              lastLogin: currentDate,
              locationAdded: currentLocation,
            },
          },
        });
      } else {
        // Check if the device ID is already registered
        const devices = userDoc.data().devices;
        if (devices && Object.values(devices).some(device => device.deviceId === deviceId)) {
          alert('This device is already registered under your account.');
          handleLogout();
          return;
        }

        // Update the user document to add the new device
        await updateDoc(userRef, {
          [`devices.${deviceName}`]: {
            deviceId,
            activated: false,
            dateAdded: currentDate,
            lastLogin: currentDate,
            locationAdded: currentLocation,
          },
        });
      }

      setIsDeviceRegistered(true);
      setErrorMessage(`This device (${deviceName}) has not been activated, please contact Admin.`);
      setErrorStyle({
        backgroundColor: 'rgba(255, 165, 0, 0.2)',
        color: 'darkorange',
        padding: '10px',
        borderRadius: '5px',
        textAlign: 'center',
        marginTop: '10px',
      });
      handleLogout();
    } catch (error) {
      console.error('Device registration failed:', error);
      alert('Failed to register device. Please try again.');
    }
  };

  const handleLogout = () => {
    signOut(auth);
    setIsLoggedIn(false);
  };

  const handleInputChange = (setter) => (event) => {
    setter(event.target.value);
    // Clear device name input and hide register device button when username or password changes
    setDeviceName('');
    setShowDeviceNameInput(false);
    setIsDeviceRegistered(false);
    setErrorMessage('');
    setErrorStyle({});
  };

  useEffect(() => {
    console.log(isLoggedIn)
  }, [isLoggedIn]);

  if (isLoggedIn) {
    return (
      <Presentation/>
    );
  }

  return (
    <div className="relative flex items-center justify-center min-h-screen bg-gradient-to-br from-gray-900 to-gray-600 overflow-hidden">
      <div className="absolute top-0 left-0 w-full h-full bg-black opacity-40"></div>
      <div className="relative w-full max-w-6xl p-10 bg-white bg-opacity-95 rounded-xl shadow-lg flex items-center" style={{ backdropFilter: 'blur(15px)' }}>
        <div className="flex-1 hidden md:block pr-10">
          <img src="./presentation/3DMA.jpg" alt="Gold Mining" className="w-full h-full object-cover rounded-lg shadow-xl transform hover:scale-105 transition-all duration-500" />
        </div>
        <div className="border-l border-gray-300 h-full mx-8"></div>
        <div className="flex-1">
          <div className="flex flex-col items-center mb-6">
            <div className="w-24 h-24 mb-4 bg-yellow-500 rounded-full flex items-center justify-center shadow-lg transform rotate-3d hover:rotate-6 hover:scale-110 transition-all duration-500">
              {/* 3D Gold Mining Icon could be placed here */}
              <span className="text-2xl font-extrabold text-gray-900 font-sans">3DMA</span>
            </div>
            <h2 className="text-4xl font-bold text-center text-yellow-400 font-serif">PRESENTING SABIMINE</h2>
          </div>
          <div className="space-y-6">
            <input
              type="email"
              placeholder="Username"
              value={username}
              onChange={handleInputChange(setUsername)}
              className="w-full px-5 py-3 border border-gray-400 rounded-lg focus:outline-none focus:ring focus:border-yellow-400 text-lg font-light shadow-md"
            />
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={handleInputChange(setPassword)}
              className="w-full px-5 py-3 border border-gray-400 rounded-lg focus:outline-none focus:ring focus:border-yellow-400 text-lg font-light shadow-md"
            />
            <button
              onClick={handleLogin}
              className="w-full px-5 py-3 text-white bg-yellow-600 rounded-lg hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500 shadow-lg transform hover:-translate-y-1 hover:scale-105 transition-all duration-300 font-bold text-lg"
            >
              Login
            </button>
            {showDeviceNameInput && (
              <div className="mt-4 space-y-4">
                <input
                  type="text"
                  placeholder="Device Name"
                  value={deviceName}
                  onChange={(e) => setDeviceName(e.target.value)}
                  className="w-full px-5 py-3 border border-gray-400 rounded-lg focus:outline-none focus:ring focus:border-yellow-400 text-lg font-light shadow-md"
                  disabled={isDeviceRegistered}
                />
                {!isDeviceRegistered && (
                  <button
                    onClick={handleRegisterDevice}
                    className="w-full px-5 py-3 text-white bg-green-600 rounded-lg hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 shadow-lg transform hover:-translate-y-1 hover:scale-105 transition-all duration-300 font-bold text-lg"
                  >
                    Register Device
                  </button>
                )}
              </div>
            )}
            {errorMessage && (
              <div style={errorStyle} className="text-center font-medium">{errorMessage}</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Authentication;
