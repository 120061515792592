// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, signOut } from "firebase/auth";
import { getFirestore} from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyB7cPnT5_-VXpCb-lkSKe-A7lcQh-DolgE",
  authDomain: "talkmore-3a9dd.firebaseapp.com",
  databaseURL: "https://talkmore-3a9dd.firebaseio.com",
  projectId: "talkmore-3a9dd",
  storageBucket: "talkmore-3a9dd.firebasestorage.app",
  messagingSenderId: "550278997466",
  appId: "1:550278997466:web:800558d8c200e1bfa58ce3"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const handleLogout = () => {
  signOut(auth);
};

export {auth,db,handleLogout};