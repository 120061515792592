import React, { useEffect, useState, useRef } from 'react';
import * as THREE from 'three';
import { fetchData } from './dataFetching';
import { transformData } from './dataTransformation';

const holeColumns = {
  'Hole No': 'Hole No',
  'X': 'X',
  'Y': 'Y',
  'Z': 'Z',
  'Azimuth': 'Azimuth',
  'Dip': 'Dip',
  'Depth': 'Depth',
};

const assayColumns = {
  'HoleID': 'HOLE ID',
  'from': 'FROM',
  'to': 'TO',
  'grade': 'SABI ASSAY VALUE (g/t)',
};

const constants =  [-90741.234,880,-2255027.981]

export default function HoleRender({key,name,hole_url,assay_url,visible}) {
  //console.log(hole_url,assay_url)
  const [combinedData, setCombinedData] = useState({});
  const [filterData, setFilterData] = useState({});
  const [filteredHoleIds, setFilteredHoleIds] = useState([]);
  const [holeCount, setHoleCount] = useState(0);
  const [assayCount, setAssayCount] = useState(0);

  useEffect(() => {
    const loadData = async () => {
      const data = await fetchData(hole_url, assay_url, holeColumns, assayColumns);
      const transformed = transformData(data, holeColumns, assayColumns, constants);
      setCombinedData(transformed);
    };

    loadData();
  }, []);

  useEffect(() => {
    const filteredData = filteredHoleIds.length>0
      ? Object.keys(combinedData).reduce((acc, key) => {
          if (filteredHoleIds.includes(key)) {
            acc[key] = combinedData[key];
          }
          return acc;
        }, {})
      : combinedData;

    setHoleCount(Object.keys(filteredData).length);
    let assayNumber = 0
    Object.keys(filteredData).forEach((hole, j) => {
        const data = filteredData[hole].assays;
        assayNumber += data.length
    })
    setAssayCount(assayNumber)
    
    setFilterData(filteredData)

    //console.log(holeCount,assayCount)

  }, [combinedData, filteredHoleIds]);

  return (
    holeCount > 0 && visible &&
    <group visible={visible} key={key} id={key}>
      <Holes count={holeCount} holes={filterData}/>
      {assayCount > 0 && <Assays count={assayCount}  holes={filterData}/>}
    </group>
      
  );
}

function Holes({ count, holes }) {
  const ref = useRef();
  const [instancesData, setInstancesData] = useState([]);
  //console.log(holes)
  useEffect(() => {
    
    let idata = [];
    Object.keys(holes).forEach((hole, i) => {
      const data = holes[hole];
      idata.push(data.data)
      const matrix = data.holeMatrix;
      ref.current.setMatrixAt(i, matrix);      
    });

    setInstancesData(idata)
    ref.current.instanceMatrix.needsUpdate = true;
  }, [holes]);

  const cubeGeo = new THREE.BoxGeometry(0.4,0.4,1)

  return (
    <instancedMesh ref={ref} args={[null, null, count]} geometry={cubeGeo} userData={{instancesData:instancesData}}  name='Holes'>
      <meshStandardMaterial attach="material" color={'#9c88ff'}/>
    </instancedMesh>
  );
}

function Assays({ count,holes }) {
  const ref = useRef();
  const [colors, setColors] = useState(new Float32Array());
  const [instancesData, setInstancesData] = useState([]);

  useEffect(() => {
    let colorsArray = [];
    let idata = [];
    let i = 0
    Object.keys(holes).forEach((hole, j) => {
      const data = holes[hole].assays;
      if (data.length > 0) {
        data.forEach((assay) => {
          const matrix = assay.matrix;
          const color = assay.color;
          const data = assay.data;
          idata.push({
            holeID:data[assayColumns.HoleID],
            from:data[assayColumns.from]  + ' m'  ,
            to: data[assayColumns.to]  + ' m' ,
            grade:data[assayColumns.grade] + ' g/t' 
          })
          ref.current.setMatrixAt(i, matrix);
          colorsArray.push(color.r, color.g, color.b);
          i++;
        });
      }
    });

    setInstancesData(idata)
    setColors(Float32Array.from(colorsArray));
    ref.current.instanceMatrix.needsUpdate = true;
  }, [holes]);

  return (
    <instancedMesh ref={ref} args={[null, null, count]} userData={{instancesData:instancesData}} name='Assays'>
      <sphereGeometry args={[0.5, 8, 8]} >
        <instancedBufferAttribute attach="attributes-color" args={[colors, 3]} />
      </sphereGeometry>
      <meshLambertMaterial vertexColors toneMapped={false} />
    </instancedMesh>
   
  );
}