import React from 'react';
import './index.css';
import Authentication from './Auth.js';

export default function App() {
  const currentProject= "SABI-001"; // Set your current project here
  return (
    <Authentication project={currentProject} />   
  )

  

 
}
