export function DMSStringToDecimal(dmsString) {
    // Define possible delimiters
    let delimiters = [',', '.', '/'];
    let delimiter

    

     // If no delimiter is found, return an error or handle appropriately
     if (!dmsString) {
        return 0
        //throw new Error("Invalid DMS string format");
    }

    // Find the first delimiter that exists in the string
    for (let i = 0; i < delimiters.length; i++) {
        if (dmsString.includes(delimiters[i])) {
            delimiter = delimiters[i];
            break;
        }
    }

    // If no delimiter is found, return an error or handle appropriately
    if (!delimiter) {
        return 0
        //throw new Error("Invalid DMS string format");
    }

    // Split the input string by the found delimiter
    let parts = dmsString.split(delimiter);

    // Parse the degrees, minutes, and seconds as integers
    let degrees = parseInt(parts[0]);
    let minutes = parseInt(parts[1]);
    let seconds = parseInt(parts[2]);

    // Convert the DMS to decimal degrees
    let decimalDegrees = Math.abs(degrees) + (minutes / 60) + (seconds / 3600);

    // Handle negative values for west longitudes and south latitudes
    if (degrees < 0) {
        decimalDegrees = -decimalDegrees;
    }


    return decimalDegrees;
}
