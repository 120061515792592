import * as THREE from 'three';
import {DMSStringToDecimal} from './DSMD.js'

const calculateEndCoordinates = (start, direction, inclination, depth) => {
  direction = DMSStringToDecimal(direction)+180
  inclination = DMSStringToDecimal(inclination)
  //console.log(start, direction, inclination, depth)
  const dirRad = (parseFloat(direction) * Math.PI) / 180;
  const incRad = (parseFloat(inclination) * Math.PI) / 180;

  //const dx = depth * Math.cos(incRad) * Math.sin(dirRad);
  //const dy = depth * Math.cos(incRad) * Math.cos(dirRad);
  //const dz = depth * Math.sin(incRad);

  const dx = depth * Math.abs(Math.cos(incRad)) * Math.sin(dirRad);
  const dy = depth * Math.sin(incRad)
  const dz = depth * Math.abs(Math.cos(incRad)) * Math.cos(dirRad);

  const coords = {
    x: start.x + dx,
    y: start.y + dy,
    z: start.z + dz,
  };

 // console.log(start)
 // console.log(coords)
  return coords
};

export const transformData = ({ holes, assays }, holeColumns, assayColumns , constants) => {
  const combinedData = {};

  Object.keys(holes).forEach((holeId) => {
    const hole = holes[holeId];
    const start = {
      x: -parseFloat(hole[holeColumns['X']]) - constants[0],
      y: parseFloat(hole[holeColumns['Z']]) - constants[1],
      z: parseFloat(hole[holeColumns['Y']]) + constants[2],
    };

    //console.log(start)

    const end = calculateEndCoordinates(
      start,
      hole[holeColumns['Azimuth']],
      hole[holeColumns['Dip']],
      hole[holeColumns['Depth']],
    );

    const points = [start, end];

    const holeAssays = assays[holeId] || [];
    const transformedAssays = holeAssays.map((assay) => {
      const fromPoint = calculateEndCoordinates(
        start,
        hole[holeColumns['Azimuth']],
        hole[holeColumns['Dip']],
        parseFloat(assay[assayColumns['from']])
      );

      const toPoint = calculateEndCoordinates(
        start,
        hole[holeColumns['Azimuth']],
        hole[holeColumns['Dip']],
        parseFloat(assay[assayColumns['to']])
      );

      const assayLength = new THREE.Vector3(
        toPoint.x - fromPoint.x,
        toPoint.y - fromPoint.y,
        toPoint.z - fromPoint.z
      ).length();

      const assayPosition = new THREE.Vector3(
        (fromPoint.x + toPoint.x) / 2,
        (fromPoint.y + toPoint.y) / 2,
        (fromPoint.z + toPoint.z) / 2
      );

      const assayRotation = new THREE.Euler().setFromRotationMatrix(
        new THREE.Matrix4().lookAt(
          new THREE.Vector3(fromPoint.x, fromPoint.y, fromPoint.z),
          new THREE.Vector3(toPoint.x, toPoint.y, toPoint.z),
          new THREE.Vector3(0, 1, 0)
        )
      );

      const assayScale = new THREE.Vector3(1, 1, assayLength);

      const assayMatrix = new THREE.Matrix4()
        .makeTranslation(assayPosition.x, assayPosition.y, assayPosition.z)
        .multiply(new THREE.Matrix4().makeRotationFromEuler(assayRotation))
        .multiply(new THREE.Matrix4().makeScale(assayScale.x, assayScale.y, assayScale.z));

      const assayValue = parseFloat(assay[assayColumns['grade']]);
      let color;
      if (assayValue < 1) color = 'green';
      else if (assayValue < 3) color = 'yellow';
      else if (assayValue >= 3) color = 'yellow';
      else color = 'gray';

      return {
        matrix: assayMatrix,
        color: new THREE.Color(color),
        data: assay,
      };
    });

    const holeMatrix = (() => {
      const lineLength = new THREE.Vector3(end.x - start.x, end.y - start.y, end.z - start.z).length();
      const position = new THREE.Vector3((start.x + end.x) / 2, (start.y + end.y) / 2, (start.z + end.z) / 2);
      const rotation = new THREE.Euler().setFromRotationMatrix(
        new THREE.Matrix4().lookAt(
          new THREE.Vector3(start.x, start.y, start.z),
          new THREE.Vector3(end.x, end.y, end.z),
          new THREE.Vector3(0, 1, 0)
        )
      );
      const scale = new THREE.Vector3(1, 1, lineLength);
      return new THREE.Matrix4()
        .makeTranslation(position.x, position.y, position.z)
        .multiply(new THREE.Matrix4().makeRotationFromEuler(rotation))
        .multiply(new THREE.Matrix4().makeScale(scale.x, scale.y, scale.z));
    })();

    combinedData[holeId] = {
      holeId,
      holeMatrix,
      points,
      assays: transformedAssays,
      data: hole,
    };
  });

  return combinedData;
};
