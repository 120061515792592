export const ModelList = [
  
    {
        'group':'SABI',
        'name':'SURFACE',
        'models':
        [
            {name:'drone',url:'./models/gltf/SABILO31-transformed.glb'},
            {name:'drone0824',url:'./models/gltf/PIT_060924C.glb'},
            {name:'drone1224',url:'./models/gltf/Pit_2Dec24C.glb'},
            {name:'google',url:'./models/gltf/TERRAIN-transformed.glb'},
            {name:'Topo',url:'./models/gltf/Topo_24SeptOpt.glb'},
        ]   

    },
    {
        'group':'SABI',
        'name':'PIT.DESIGNS',
        'models':
        [
            {name:'PitOutline',url:'./models/gltf/pit_outline-transformed.glb'},
            {name:'PitOutline1',url:'./models/gltf/pit_outline1-transformed.glb'},
            {name:'Pit1',url:'./models/gltf/Pit01.gltf'},
            {name:'Pit2',url:'./models/gltf/PIT_170224-transformed.glb'},
            {name:'Pit3',url:'./models/gltf/PIT_170224_C-transformed.glb'},
            {name:'Pit4',url:'./models/gltf/PIT_270224-transformed.glb'},
            {name:'Pit5',url:'./models/gltf/PIT_110424-transformed.glb'},
            {name:'Pit24Sept',url:'./models/gltf/Pit_24Sept.glb'},
            {name:'Road',url:'./models/gltf/haul_road.glb'},
        ]   

    },
    {
        'group':'SABI',
        'name':'SURVEY.DEVELOPMENT',
        'models':
        [
            {name:'Trenches',url:'./models/gltf/TRENCHES.gltf'},
            {name:'Quary',url:'./models/gltf/QUARRY.gltf'},
            {name:'Shaft',url:'./models/gltf/SHAFT.glb'},
            {name:'Raises',url:'./models/gltf/RAISE-transformed.glb'},
            {name:'0L',url:'./models/gltf/0L_08052024-transformed.glb'},
            {name:'30ML',url:'./models/gltf/30mL_kenny060924.glb'},
            {name:'HL',url:'./models/gltf/HL.glb'},
            {name:'2L',url:'./models/gltf/2L_kenny060924.glb'},
            {name:'3L',url:'./models/gltf/3L.glb'},
            {name:'5L',url:'./models/gltf/5L.glb'},
        ]   

    },
    {
        'group':'SABI',
        'name':'GEOLOGY.OREBODIES',
        'models':
        [
            {name:'Orebody',url:'./models/gltf/OREBODY.glb'},
            {name:'Orebody01',url:'./models/gltf/OREBODY01.glb'},
            {name:'Orebody_0824',url:'./models/gltf/OB_Culton_060924.glb'},
            {name:'OREBODY190924',url:'./models/gltf/OREBODY190924.glb'},
            {name:'OREBODY190924_1',url:'./models/gltf/OREBODY190924_1.glb'},
            {name:'OREBODY190924_2',url:'./models/gltf/OREBODY190924_2.glb'},
            ]   

    },
    {
        'group':'Holes',
        'name':'Holes',
        'models':
        [
            {type:'assays',name:'UG_ASSAYS',url:'./files/sabi_sampling.csv'},
            {type:'holesassays',name:'Holes',assay_url:'./files/AssayData.csv' , hole_url:'./files/HoleData.csv'},
            ]   

    }
]