import React, { useRef, useState, useEffect } from 'react';
import * as THREE from 'three';
import * as d3 from 'd3';
export default function AssayLoader({name,url,visible}) {
    const floader = new THREE.FileLoader();
    const [assayData, setAssays] = useState([]);

    const coordinateConstantLo31 = { constantX: -90741.234, constantZ: -2255027.981, constantY: 880 };

    const levels = {
        '0L': { level: 901 },
        '3LA': { level: 812.5 },
        '3LC': { level: 812.5 },
        '3LE': { level: 812.5 },
        '3LF': { level: 812.5 },
        '5L2': { level: 762.5 },
        '5L4': { level: 762.5 },
        'HUMORIST1': { level: 894 },
        'HUMORIST2': { level: 895.5 },
        'HUMORIST3': { level: 895.5 }
    };

    function loadAssay() {
        floader.load(
            url,
            function (data) {
                const rows = d3.csvParse(data);
                let assays = [];
                rows.forEach(function (record) {
                    var displayData = {};
                    var rl = levels[record.LEVEL].level;

                    if (Number(record.width) && Number(record.width) > 0) {
                        displayData = {
                            holeid: record.CH,
                            channelID: record.CH,
                            length: Number(record.width).toFixed(2),
                            grade: Number(record.Au1)
                        };

                        placechannel(displayData, record.centx, record.centy, rl, 0, Number(record.azimuth),0,displayData.length);
                    } else {
                        let sampleNumber = 0;
                        const grades = [];
                        for (let i = 1; i <= 7; i++) {
                            const grade = Number(record[`Au${i}`]);
                            if (grade > 0) {
                                sampleNumber++;
                                grades.push(grade);
                            }
                        }

                        if (sampleNumber === 0) return;

                        const sampleLength = Number(record.length) / sampleNumber;
                        grades.forEach((grade, idx) => {
                            displayData = {
                                holeid: record.CH,
                                channelID: `${record.CH}_Au${idx + 1}`,
                                length: sampleLength.toFixed(2),
                                grade: grade
                            };
                            placechannel(displayData, record.startx, record.starty, rl, sampleLength * (idx + 0.5), Number(record.azimuth),sampleLength * (idx + 0),sampleLength * (idx + 1));
                        });
                    }
                });
                setAssays(assays);

                function placechannel(dd, x, y, z, d, directionDegrees,from=0,to=0) {
                    var directionRadians = THREE.MathUtils.degToRad(Number(directionDegrees));
                    var a = new THREE.Vector3(Math.cos(directionRadians), Math.sin(directionRadians), 0).normalize();
                    if (directionDegrees >= 90 && directionDegrees < 180) {
                        a = new THREE.Vector3(Math.sin(directionRadians - Math.PI / 2), Math.cos(directionRadians - Math.PI / 2), 0).normalize();
                    }
                    if (directionDegrees >= 290 && directionDegrees < 360) {
                        a = new THREE.Vector3(Math.sin(directionRadians - Math.PI / 2), Math.cos(directionRadians - Math.PI / 2), 0).normalize();
                    }

                    var startX = x - coordinateConstantLo31.constantX;
                    var startY = z - coordinateConstantLo31.constantY;
                    var startZ = coordinateConstantLo31.constantZ - y;

                    var spherePosition = new THREE.Vector3(
                        startX + a.y * d,
                        startY + a.z * d,
                        startZ + a.x * d
                    );

                    assays.push({ pos: spherePosition, grade: dd.grade , holeid:dd.holeid,channelid:dd.channelID,from,to });
                }
            },
            function (xhr) {
                //console.log((xhr.loaded / xhr.total * 100) + '% loaded');
            },
            function (err) {
                console.error('An error happened');
            }
        );
       // console.log(assayData)
    }

    useEffect(() => {
        loadAssay();
    }, []);

    return (
        <SphereInstancedMesh assayData={assayData} visible={visible} />
    );
}

const SphereInstancedMesh = ({ key, assayData , visible }) => {
    const meshRef = useRef();
    const count = assayData.length;
    const [colors, setColors] = useState(new Float32Array(count));
    const [instancesData, setInstancesData] = useState([]);

    useEffect(() => {
        let idata = [];
        if (meshRef.current) {
            const dummy = new THREE.Object3D();
            const color = new THREE.Color();
            const colorsArray = new Float32Array(assayData.length * 3);

            console.log(assayData)
            assayData.forEach((assay, i) => {
                dummy.position.copy(assay.pos);
                dummy.updateMatrix();
                meshRef.current.setMatrixAt(i, dummy.matrix);
               // console.log(assay)
                idata.push({grade:assay.grade+' g/t'})

                const grade = assay.grade;
                if (grade > 5) {
                    color.set(0xA020F0); // purple
                } else if (grade > 3) {
                    color.set(0xfc6a03); // orange
                } else if (grade > 1) {
                    color.set(0x00ff00); // green
                } else {
                    color.set(0xfffff0); // white
                }
                
                colorsArray[i * 3] = color.r;
                colorsArray[i * 3 + 1] = color.g;
                colorsArray[i * 3 + 2] = color.b;
            });
            setColors(colorsArray)

            setInstancesData(idata)
            meshRef.current.instanceMatrix.needsUpdate = true;
            //meshRef.current.instanceColor.needsUpdate = true;
        }
    }, [assayData]);

    return (
        <instancedMesh visible={visible} ref={meshRef} args={[null, null, count]}  userData={{instancesData:instancesData}} >
            <sphereGeometry args={[0.5, 4, 4]}>
                <instancedBufferAttribute attach="attributes-color" args={[colors, 3]} />
            </sphereGeometry>
            <meshLambertMaterial vertexColors toneMapped={false} />
        </instancedMesh>
    );
};
