import React, { Suspense, useRef } from "react";
import { Environment, OrbitControls, Sphere, useTexture } from "@react-three/drei";
import { DoubleSide, TextureLoader } from "three";
import { Canvas, useLoader } from "@react-three/fiber";

const SphereViewer = ({ imageUrl }) => {
  console.log(imageUrl)
  const colorMap = useTexture(imageUrl)
   // Flip the texture horizontally
   colorMap.flipY = false;
   colorMap.needsUpdate = true;
  return (

    <mesh  rotation={[-Math.PI, 0, 0]}>
        <sphereGeometry args={[10, 32, 32]} />
        <meshStandardMaterial attach='material'  map={colorMap}  side={DoubleSide}  />
    </mesh>
  );
};

const ImageViewer = ({ imageUrl }) => {

  const controlRef = useRef()
  return (
    <>
    <Canvas className="absolute inset-0 z-40" flat shadows gl={{ antialias: true }} camera={{ position: [0, 0, 0.1], near: 0.5, far: 50, fov: 75 }}>
      <OrbitControls makeDefault  ref={controlRef} 
        target={[0,0,0]}
        enableRotate={true} // Disable rotation
        enablePan={false}    // Disable panning
        minDistance={0}      // Allow zoom in to the origin
        maxDistance={10}      // Restrict zooming out to a radius of 5
      />
      <ambientLight intensity={2} />
      {/* <directionalLight position={[5, 5, 5]} intensity={0.5} />    */}
      <Suspense fallback={null}>
        <SphereViewer imageUrl={imageUrl} />
      </Suspense>
    </Canvas>
      
      
    </>
       
  );
};

export default ImageViewer;
