import React, { Suspense, useRef } from "react";
import { Environment, OrbitControls, Sphere, useTexture } from "@react-three/drei";
import { DoubleSide, TextureLoader } from "three";
import { Canvas, useLoader } from "@react-three/fiber";
import LoadModel from "../../Utils/LoadModel";

const ScanViewer = ({ name, url }) => {

  const controlRef = useRef()
  return (
    <>
    <Canvas className="absolute inset-0 z-50" flat shadows gl={{ antialias: true }} camera={{ position: [0, 0, 0.1], near: 0.5, far: 50, fov: 75 }}>
      <OrbitControls makeDefault  ref={controlRef} 
        target={[0,0,0]}
        enableRotate={true} // Disable rotation
        enablePan={false}    // Disable panning
             // Restrict zooming out to a radius of 5
      />
      <ambientLight intensity={2} />
      {/* <directionalLight position={[5, 5, 5]} intensity={0.5} />    */}
      <LoadModel key={name} name={name} url={url}/>
    </Canvas>
      
      
    </>
       
  );
};

export default ScanViewer;
