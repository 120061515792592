import React, { useState, useEffect } from 'react';
import {
  BarChart,
  Bar,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

const DevelopmentStatistics = () => {
  // Define the current month (November 2024)
  const currentYear = 2024;
  const currentMonth = 10; // November (0-indexed)

  const developmentData = [
    // November 1st
    { endName: '2L_D8_EAST', date: '2024-11-01', planAdvance: 3, actualAdvance: 0 },
    { endName: '2L_D8_WEST', date: '2024-11-01', planAdvance: 0, actualAdvance: 2 },
    { endName: '4L_D2_WEST', date: '2024-11-01', planAdvance: 4, actualAdvance: 3 },

    // November 2nd
    { endName: '2SL_D2_EAST', date: '2024-11-02', planAdvance: 2, actualAdvance: 2 },
    { endName: '2SL_D2_WEST', date: '2024-11-02', planAdvance: 3, actualAdvance: 0 },
    { endName: '5L_D16_EAST', date: '2024-11-02', planAdvance: 0, actualAdvance: 1 },

    // November 3rd
    { endName: '3L_D8_EAST', date: '2024-11-03', planAdvance: 4, actualAdvance: 4 },
    { endName: '3L_D8_WEST', date: '2024-11-03', planAdvance: 0, actualAdvance: 2 },
    { endName: '4L_D16_WEST', date: '2024-11-03', planAdvance: 3, actualAdvance: 0 },

    // November 4th
    { endName: '2SL2_D2_EAST', date: '2024-11-04', planAdvance: 2, actualAdvance: 0 },
    { endName: '2SL2_D2_WEST', date: '2024-11-04', planAdvance: 0, actualAdvance: 3 },
    { endName: '5L_D8_EAST', date: '2024-11-04', planAdvance: 1, actualAdvance: 1 },

    // November 5th
    { endName: '2L_D8_EAST', date: '2024-11-05', planAdvance: 0, actualAdvance: 2 },
    { endName: '2L_D8_WEST', date: '2024-11-05', planAdvance: 2, actualAdvance: 0 },
    { endName: '4L_D2_WEST', date: '2024-11-05', planAdvance: 4, actualAdvance: 3 },

    // November 6th
    { endName: '2SL_D2_EAST', date: '2024-11-06', planAdvance: 0, actualAdvance: 2 },
    { endName: '2SL_D2_WEST', date: '2024-11-06', planAdvance: 3, actualAdvance: 0 },
    { endName: '5L_D16_EAST', date: '2024-11-06', planAdvance: 1, actualAdvance: 1 },

    // November 7th
    { endName: '3L_D8_EAST', date: '2024-11-07', planAdvance: 4, actualAdvance: 0 },
    { endName: '3L_D8_WEST', date: '2024-11-07', planAdvance: 0, actualAdvance: 2 },
    { endName: '4L_D16_WEST', date: '2024-11-07', planAdvance: 3, actualAdvance: 3 },

    // November 8th
    { endName: '2SL2_D2_EAST', date: '2024-11-08', planAdvance: 2, actualAdvance: 2 },
    { endName: '2SL2_D2_WEST', date: '2024-11-08', planAdvance: 0, actualAdvance: 3 },
    { endName: '5L_D8_EAST', date: '2024-11-08', planAdvance: 1, actualAdvance: 0 },

    // November 9th
    { endName: '2L_D8_EAST', date: '2024-11-09', planAdvance: 3, actualAdvance: 2 },
    { endName: '2L_D8_WEST', date: '2024-11-09', planAdvance: 0, actualAdvance: 2 },
    { endName: '4L_D2_WEST', date: '2024-11-09', planAdvance: 4, actualAdvance: 0 },

    // November 10th
    { endName: '2SL_D2_EAST', date: '2024-11-10', planAdvance: 2, actualAdvance: 0 },
    { endName: '2SL_D2_WEST', date: '2024-11-10', planAdvance: 0, actualAdvance: 3 },
    { endName: '5L_D16_EAST', date: '2024-11-10', planAdvance: 1, actualAdvance: 1 },

    // // November 11th
    // { endName: '3L_D8_EAST', date: '2024-11-11', planAdvance: 4, actualAdvance: 4 },
    // { endName: '3L_D8_WEST', date: '2024-11-11', planAdvance: 0, actualAdvance: 2 },
    // { endName: '4L_D16_WEST', date: '2024-11-11', planAdvance: 3, actualAdvance: 0 },

    // // November 12th
    // { endName: '2SL2_D2_EAST', date: '2024-11-12', planAdvance: 2, actualAdvance: 1 },
    // { endName: '2SL2_D2_WEST', date: '2024-11-12', planAdvance: 0, actualAdvance: 2 },
    // { endName: '5L_D8_EAST', date: '2024-11-12', planAdvance: 1, actualAdvance: 0 },

    // // November 13th
    // { endName: '2L_D8_EAST', date: '2024-11-13', planAdvance: 3, actualAdvance: 3 },
    // { endName: '2L_D8_WEST', date: '2024-11-13', planAdvance: 0, actualAdvance: 1 },
    // { endName: '4L_D2_WEST', date: '2024-11-13', planAdvance: 4, actualAdvance: 4 },

    // // November 14th
    // { endName: '2SL_D2_EAST', date: '2024-11-14', planAdvance: 2, actualAdvance: 2 },
    // { endName: '2SL_D2_WEST', date: '2024-11-14', planAdvance: 0, actualAdvance: 0 },
    // { endName: '5L_D16_EAST', date: '2024-11-14', planAdvance: 1, actualAdvance: 1 },

    // // November 15th
    // { endName: '3L_D8_EAST', date: '2024-11-15', planAdvance: 4, actualAdvance: 3 },
    // { endName: '3L_D8_WEST', date: '2024-11-15', planAdvance: 0, actualAdvance: 2 },
    // { endName: '4L_D16_WEST', date: '2024-11-15', planAdvance: 3, actualAdvance: 0 },

    // // November 16th
    // { endName: '2SL2_D2_EAST', date: '2024-11-16', planAdvance: 2, actualAdvance: 2 },
    // { endName: '2SL2_D2_WEST', date: '2024-11-16', planAdvance: 0, actualAdvance: 3 },
    // { endName: '5L_D8_EAST', date: '2024-11-16', planAdvance: 1, actualAdvance: 0 },

    // // November 17th
    // { endName: '2L_D8_EAST', date: '2024-11-17', planAdvance: 3, actualAdvance: 1 },
    // { endName: '2L_D8_WEST', date: '2024-11-17', planAdvance: 0, actualAdvance: 2 },
    // { endName: '4L_D2_WEST', date: '2024-11-17', planAdvance: 4, actualAdvance: 3 },

    // // November 18th
    // { endName: '2SL_D2_EAST', date: '2024-11-18', planAdvance: 2, actualAdvance: 0 },
    // { endName: '2SL_D2_WEST', date: '2024-11-18', planAdvance: 0, actualAdvance: 3 },
    // { endName: '5L_D16_EAST', date: '2024-11-18', planAdvance: 1, actualAdvance: 1 },

    // // November 19th
    // { endName: '3L_D8_EAST', date: '2024-11-19', planAdvance: 4, actualAdvance: 4 },
    // { endName: '3L_D8_WEST', date: '2024-11-19', planAdvance: 0, actualAdvance: 2 },
    // { endName: '4L_D16_WEST', date: '2024-11-19', planAdvance: 3, actualAdvance: 0 },

    // // November 20th
    // { endName: '2SL2_D2_EAST', date: '2024-11-20', planAdvance: 2, actualAdvance: 2 },
    // { endName: '2SL2_D2_WEST', date: '2024-11-20', planAdvance: 0, actualAdvance: 3 },
    // { endName: '5L_D8_EAST', date: '2024-11-20', planAdvance: 1, actualAdvance: 0 },

    // // November 21st
    // { endName: '2L_D8_EAST', date: '2024-11-21', planAdvance: 3, actualAdvance: 2 },
    // { endName: '2L_D8_WEST', date: '2024-11-21', planAdvance: 0, actualAdvance: 2 },
    // { endName: '4L_D2_WEST', date: '2024-11-21', planAdvance: 4, actualAdvance: 0 },
  ];

  // Calculate tonnage (assuming 1 meter = 10 tons)
  const conversionFactor = 10;

  // Calculate tonnage and cost for each entry
  developmentData.forEach((entry) => {
    entry.planTonnage = entry.planAdvance * conversionFactor;
    entry.actualTonnage = entry.actualAdvance * conversionFactor;

    // Calculate cost (assuming cost per meter is $232)
    entry.planCost = entry.planAdvance * 232;
    entry.actualCost = entry.actualAdvance * 232;
  });

  // Compute compliance for advance
  const computeCompliance = () => {
    const endsWithPlan = developmentData.filter((entry) => entry.planAdvance > 0);

    const totalPlanAdvance = endsWithPlan.reduce(
      (sum, entry) => sum + entry.planAdvance,
      0
    );
    const totalActualAdvance = endsWithPlan.reduce(
      (sum, entry) => sum + entry.actualAdvance,
      0
    );

    const compliance = (totalActualAdvance / totalPlanAdvance) * 100;

    return compliance.toFixed(1); // Return with one decimal place
  };

  const compliancePercentage = computeCompliance();

  // Determine compliance color for advance
  let complianceColor = 'red';
  if (compliancePercentage >= 85) {
    complianceColor = 'green';
  } else if (compliancePercentage >= 75) {
    complianceColor = 'orange';
  }

  // Compute compliance for cost
  const computeCostCompliance = () => {
    const totalPlanCost = developmentData.reduce(
      (sum, entry) => sum + entry.planCost,
      0
    );
    const totalActualCost = developmentData.reduce(
      (sum, entry) => sum + entry.actualCost,
      0
    );

    const costCompliance = (totalPlanCost / totalActualCost) * 100;

    return costCompliance.toFixed(1); // Return with one decimal place
  };

  const costCompliancePercentage = computeCostCompliance();

  // Determine compliance color for cost
  let costComplianceColor = 'red';
  if (costCompliancePercentage >= 100) {
    costComplianceColor = 'green';
  } else if (costCompliancePercentage >= 90) {
    costComplianceColor = 'orange';
  }

  // State to handle header styling on scroll
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const scrollContainer = document.getElementById('dev-stats-scroll-container');

    const handleScroll = () => {
      if (scrollContainer.scrollTop > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    scrollContainer.addEventListener('scroll', handleScroll);

    return () => {
      scrollContainer.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Filter data for the current month and dates from Nov 1 to Nov 21
  const dataForCurrentMonth = developmentData.filter((entry) => {
    const entryDate = new Date(entry.date);
    return (
      entryDate.getFullYear() === currentYear &&
      entryDate.getMonth() === currentMonth &&
      entryDate.getDate() >= 1 &&
      entryDate.getDate() <= 21
    );
  });

  // Aggregate Advance, Tonnage, and Cost per Day
  const aggregatePerDay = {};
  dataForCurrentMonth.forEach((entry) => {
    const day = new Date(entry.date).getDate().toString();
    if (!aggregatePerDay[day]) {
      aggregatePerDay[day] = {
        day,
        planAdvance: 0,
        actualAdvance: 0,
        planTonnage: 0,
        actualTonnage: 0,
        planCost: 0,
        actualCost: 0,
      };
    }
    aggregatePerDay[day].planAdvance += entry.planAdvance;
    aggregatePerDay[day].actualAdvance += entry.actualAdvance;
    aggregatePerDay[day].planTonnage += entry.planTonnage;
    aggregatePerDay[day].actualTonnage += entry.actualTonnage;
    aggregatePerDay[day].planCost += entry.planCost;
    aggregatePerDay[day].actualCost += entry.actualCost;
  });

  const dailyData = Object.values(aggregatePerDay);

  // Aggregate Advance per End for the Month
  const aggregatePerEnd = {};
  dataForCurrentMonth.forEach((entry) => {
    const endName = entry.endName;
    if (!aggregatePerEnd[endName]) {
      aggregatePerEnd[endName] = {
        endName,
        planAdvance: 0,
        actualAdvance: 0,
      };
    }
    aggregatePerEnd[endName].planAdvance += entry.planAdvance;
    aggregatePerEnd[endName].actualAdvance += entry.actualAdvance;
  });

  const endData = Object.values(aggregatePerEnd);

  // Custom Tooltip Component
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      // Get the end name from the payload
      const endName = payload[0].payload.endName;

      return (
        <div className="bg-white p-2 border rounded shadow">
          <p className="font-bold text-black">{endName}</p>
          {payload.map((entry, index) => (
            <p key={`item-${index}`} style={{ color: entry.color }}>
              {entry.name}: {entry.value} m
            </p>
          ))}
        </div>
      );
    }

    return null;
  };

  return (
    <div className="h-full w-full flex flex-col overflow-hidden">
      {/* Sticky Heading */}
      <div
        className={`sticky top-0 flex justify-center items-center transition-all duration-300 z-10 ${
          isScrolled && false
            ? 'bg-blue-500 text-white rounded-full mx-4 mt-2 py-1 px-4 shadow-md'
            : 'bg-white text-gray-800 py-2'
        }`}
      >
        <h2
          className={`font-bold transition-all duration-300 ${
            isScrolled && false ? 'text-lg' : 'text-xl md:text-2xl lg:text-3xl'
          }`}
        >
          Development Trend
        </h2>
      </div>

      {/* Scrollable Content */}
      <div id="dev-stats-scroll-container" className="flex-1 overflow-y-auto px-5 pt-4 pb-4">
        <div className="space-y-6">
          {/* Compliance Cards */}
          <div className="flex flex-wrap justify-center gap-4">
            {/* Advance Compliance Card */}
            <div
              className={`bg-white p-4 rounded-lg shadow-lg w-full max-w-xs flex flex-col items-center border-t-4 ${
                complianceColor === 'green'
                  ? 'border-green-500'
                  : complianceColor === 'orange'
                  ? 'border-orange-500'
                  : 'border-red-500'
              }`}
            >
              <h4 className="text-gray-800 font-semibold mb-2 text-center">
                Advance Compliance
              </h4>
              <span
                className={`text-3xl sm:text-4xl font-bold ${
                  complianceColor === 'green'
                    ? 'text-green-500'
                    : complianceColor === 'orange'
                    ? 'text-orange-500'
                    : 'text-red-500'
                }`}
              >
                {compliancePercentage}%
              </span>
              <span className="text-gray-500 text-center">Compliance to Plan</span>
            </div>

            {/* Cost Compliance Card */}
            <div
              className={`bg-white p-4 rounded-lg shadow-lg w-full max-w-xs flex flex-col items-center border-t-4 ${
                costComplianceColor === 'green'
                  ? 'border-green-500'
                  : costComplianceColor === 'orange'
                  ? 'border-orange-500'
                  : 'border-red-500'
              }`}
            >
              <h4 className="text-gray-800 font-semibold mb-2 text-center">
                Cost Compliance
              </h4>
              <span
                className={`text-3xl sm:text-4xl font-bold ${
                  costComplianceColor === 'green'
                    ? 'text-green-500'
                    : costComplianceColor === 'orange'
                    ? 'text-orange-500'
                    : 'text-red-500'
                }`}
              >
                {costCompliancePercentage}%
              </span>
              <span className="text-gray-500 text-center">Compliance to Plan</span>
            </div>
          </div>

          {/* Advance per Day */}
          <div className="bg-white p-4 rounded-lg shadow-lg">
            <h3 className="text-lg font-semibold mb-2 text-center text-gray-800">
              Advance per Day in Current Month
            </h3>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={dailyData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="day"
                  label={{ value: 'Day', position: 'insideBottom', dy: 10 }}
                />
                <YAxis unit=" m" />
                <Tooltip formatter={(value) => `${value} m`} />
                <Legend />
                <Bar dataKey="planAdvance" name="Plan" fill="#FF0000" />
                <Bar dataKey="actualAdvance" name="Actual" fill="#0000FF" />
              </BarChart>
            </ResponsiveContainer>
          </div>

          {/* Tonnage per Day */}
          <div className="bg-white p-4 rounded-lg shadow-lg">
            <h3 className="text-lg font-semibold mb-2 text-center text-gray-800">
              Tonnage per Day in Current Month
            </h3>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart data={dailyData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="day"
                  label={{ value: 'Day', position: 'insideBottom', dy: 10 }}
                />
                <YAxis unit=" t" />
                <Tooltip formatter={(value) => `${value} t`} />
                <Legend />
                <Line type="monotone" dataKey="planTonnage" name="Plan" stroke="#FF0000" />
                <Line type="monotone" dataKey="actualTonnage" name="Actual" stroke="#0000FF" />
              </LineChart>
            </ResponsiveContainer>
          </div>

          {/* Cost per Day */}
          <div className="bg-white p-4 rounded-lg shadow-lg">
            <h3 className="text-lg font-semibold mb-2 text-center text-gray-800">
              Cost per Day in Current Month
            </h3>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={dailyData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="day"
                  label={{ value: 'Day', position: 'insideBottom', dy: 10 }}
                />
                <YAxis unit=" $" />
                <Tooltip formatter={(value) => `$${value}`} />
                <Legend />
                <Bar dataKey="planCost" name="Plan Cost" fill="#FF0000" />
                <Bar dataKey="actualCost" name="Actual Cost" fill="#0000FF" />
              </BarChart>
            </ResponsiveContainer>
          </div>

          {/* Total Advance per End for the Month */}
          <div className="bg-white p-4 rounded-lg shadow-lg">
            <h3 className="text-lg font-semibold mb-2 text-center text-gray-800">
              Total Advance per End for the Month
            </h3>
            <ResponsiveContainer width="100%" height={400}>
              <BarChart data={endData} layout="vertical">
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis type="number" unit=" m" />
                <YAxis dataKey="endName" type="category" tick={{ fontSize: 6, angle: -90, textAnchor: 'middle', color:'black'  }}/>
                <Tooltip content={CustomTooltip} />
                <Legend />
                <Bar dataKey="planAdvance" name="Plan" fill="#FF0000" />
                <Bar dataKey="actualAdvance" name="Actual" fill="#0000FF" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DevelopmentStatistics;
